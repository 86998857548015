import { Box, Button, Card, CircularProgress, Grid, IconButton, Modal, Stack, Switch, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useReporting } from "features/store";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { setCompPreviewParams } from "features/reporting/reportingSlice";
import { useEffect, useState } from "react";
import { useGetCompositeReportPreviewQuery, useGetCompositeReportQuery, useStartCompositeReportJobMutation } from "features/trading/accounts";
import { AccountPreview } from "./AccountPreview";
import { CompositeReportJobStatus, ReportingMode } from "constants/index";
import { AllAccounts } from "./AllAccounts";
import CustomDatePicker, { DateObject } from "react-multi-date-picker";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

const DEFAULT_RANGE = [null, null];

export const ReportingModal = ({ isOpened, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { compPreviewParams, reportingMode } = useReporting();
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false);
  const [range, setRange] = useState<(string | null)[]>(DEFAULT_RANGE);

  useEffect(() => {
    setRange(DEFAULT_RANGE);
  }, [isSwitchChecked]);

  const onYMChange = (value: Dayjs | null) => {
    if (!value) {
      setRange(DEFAULT_RANGE);
      return;
    }

    const startDate = value.format("YYYY-MM") + "-01";
    const endDate = value.endOf("month").format("YYYY-MM-DD");
    setRange([startDate, endDate]);
  };

  const onRangeChange = (dates: DateObject[]) => {
    if (dates.length === 2) {
      const [startDate, endDate] = dates;
      setRange([
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
      ]);
      return;
    }

    setRange(DEFAULT_RANGE);
  }

  const updateRangeParams = () => {
    const [start_date, end_date] = range;
    dispatch(setCompPreviewParams({ ...compPreviewParams, start_date, end_date }));
  }

  const { composite_id, account_id, start_date, end_date } = compPreviewParams;
  const skipPreview = reportingMode !== ReportingMode.ACCOUNT_PREVIEW || !composite_id || !account_id || !start_date || !end_date
  const { currentData: currentPreviewData, isLoading: isPreviewLoading, isFetching: isPreviewFetching } = useGetCompositeReportPreviewQuery(
    compPreviewParams as any,
    { skip: skipPreview }
  );

  const [reportJobFinished, setReportJobFinished] = useState(true);
  const [startCompositeReportJob] = useStartCompositeReportJobMutation();

  const skipReport = reportingMode !== ReportingMode.ALL_ACCOUNTS || !composite_id || !start_date || !end_date;
  const reportKey = `${composite_id}_${start_date?.split("-").join("")}_${end_date?.split("-").join("")}_report`;
  const { currentData: currentReportData, isLoading: isReportLoading, isFetching: isReportFetching } = useGetCompositeReportQuery(
    { key: reportKey },
    { skip: skipReport, pollingInterval: !reportJobFinished ? 1_000 : 0 }
  );

  const title = reportingMode === ReportingMode.ACCOUNT_PREVIEW ? "Composite Report Preview" : "Composite Report";
  const showLoader = () => {
    if (reportingMode === ReportingMode.ACCOUNT_PREVIEW) {
      return isPreviewLoading || isPreviewFetching;
    }

    return !reportJobFinished;
  };

  const onGetClick = () => {
    updateRangeParams();
    if (reportingMode === ReportingMode.ALL_ACCOUNTS) {
      const [start_date, end_date] = range;
      const params = { ...compPreviewParams, start_date, end_date };
      startCompositeReportJob(params as any);
    }
  }

  useEffect(() => {
    if (!currentReportData) {
      return;
    }

    const { status } = currentReportData;
    setReportJobFinished(status === CompositeReportJobStatus.COMPLETED || status === CompositeReportJobStatus.FAILED);
  }, [currentReportData]);

  const renderAllAccounts = ReportingMode.ALL_ACCOUNTS
    && currentReportData
    && currentReportData?.status === CompositeReportJobStatus.COMPLETED
    && currentReportData.result;

  return (
    <Modal open={isOpened} onClose={onClose}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Box component="div" sx={{ width: "1200px" }}>
          <Card>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" component="h5" sx={{ padding: 1 }}>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingY: 1, paddingLeft: 1 }} alignItems="center">
              <Grid item marginRight={2}>
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography>Month</Typography>
                  <Switch value={isSwitchChecked} onChange={(e) => setIsSwitchChecked(e.target.checked)} />
                  <Typography>Range</Typography>
                </Stack>
              </Grid>
              <Grid item marginRight={2}>
                {isSwitchChecked
                ? (
                  <CustomDatePicker
                    range
                    format="MM/DD/YYYY"
                    onChange={onRangeChange}
                    dateSeparator=" - "
                    style={{ height: "56px", width: "200px", fontSize: "16px" }}
                    placeholder="Pick range"
                    showOtherDays
                  />
                )
                : (
                  <DatePicker
                    views={['year', 'month']}
                    format="MM/YYYY"
                    onChange={onYMChange}
                    sx={{ height: "56px", width: "200px" }}
                    slotProps={{ textField: { placeholder: "Pick month" } }}
                  />
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => onGetClick()}
                >
                  Get
                </Button>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingY: 1, paddingLeft: 1, height: "800px", overflowY: "scroll" }}>
              {showLoader() && <CircularProgress />}
              {reportingMode === ReportingMode.ACCOUNT_PREVIEW && currentPreviewData && (
                <AccountPreview data={currentPreviewData} />
              )}
              {renderAllAccounts && currentReportData.result && (
                <AllAccounts data={currentReportData.result} />
              )}
            </Grid>
          </Card>
        </Box>
      </Stack>
    </Modal>
  )
};
