import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridValueFormatterParams } from "@mui/x-data-grid";
import { CompositeReportItem } from "features/trading/accounts"
import { formatPercent, formatReportNumber, formatUsDate } from "helpers";

interface Props {
  data: CompositeReportItem[];
}

export const AllAccounts = ({ data }: Props) => {
  const columnsDef: GridColDef[] = [
    {
      field: "account_number",
      headerName: "Account Number",
      width: 150,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatUsDate(value),
    },
    {
      field: "end_date",
      headerName: "End Date",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatUsDate(value),
    },
    {
      field: "start_value",
      headerName: "Start Value",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatReportNumber(value),
      width: 150,
    },
    {
      field: "end_value",
      headerName: "End Value",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatReportNumber(value),
      width: 150,
    },
    {
      field: "dividend_income",
      headerName: "Dividend Income",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatReportNumber(value),
      width: 150,
    },
    {
      field: "perfomance",
      headerName: "Perfomance",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatPercent(value),
    },
    {
      field: "portfolio_percent",
      headerName: "% of Portfolio",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatPercent(value),
      width: 130,
    },
    {
      field: "weighted_average",
      headerName: "Weighted Average",
      valueFormatter: ({ value }: GridValueFormatterParams) => formatPercent(value),
      width: 160,
    },
  ].map((colDef) => ({ ...colDef, align: "right", headerAlign: "right" }));

  const toolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport fileName={`composite_perfomance_report.csv`} />
      </GridToolbarContainer>
    );
  };

  return (
    <DataGrid
      getRowId={(row) => row.account_number}
      rows={data}
      rowCount={data.length}
      columns={columnsDef}
      density="compact"
      slots={{
        toolbar: toolbar
      }}
      hideFooter
    />
  );
}
