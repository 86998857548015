import { OptionSymbolParams } from "helpers";
import api, { GridModel, ListResponse, gridModelsToQuery } from "../api";
import { CompositeReportJobStatus } from "constants/index";

interface CommonModel {
  id: number;
  created_on: string;
  created_by: number | null;
  updated_on: string;
  updated_by: number | null;
}

export interface Alert extends CommonModel {
  type: string;
  payload: {
    body: string;
  };
  status: string;
}

export interface UserAlert extends CommonModel {
  seen: boolean;
  type: string;
  payload: any;
  status: string;
}

export interface Advisor extends CommonModel {
  name: string;
}

export interface Account {
  id: number;
  client: number;
  account_number: string;
  orion_account_id: number;
  client__name: string;
  client__representative: number;
  client__representative__name: string;
  custodian: number;
  custodian__name: string;
  reg_type: number;
  reg_type__name: string;
  as_of_date: string;
}

export interface AccountStrategy {
  id: number;
  account: number;
  strategy: number;
  available_quantity: number;
  composite: boolean;
  reporting: string | null;
  reinvest: boolean;
  keyword: boolean;
  underlying: string | null;
  management: string | null;
  advisor: number | null;
  notes: string | null;
  strategy__name: string;
  advisor__name: string | null;
  client__name: string;
  account__number: string;
  custodian__name: string;
}

export interface Asset extends CommonModel {
  as_of_date: string;
  symbol: string;
  name: string;
  last_price: string;
}

export interface StrategyPosition extends Account {
  position_id: number;
  stock_qty: number;
  available_stock_qty: string;
  option_qty: string;
  overriden_option_qty: string | null;
  new_option_pos: string;
  on_order_sell: string | null;
  on_order_buy: string | null;
  executed_order_sell: string | null;
  executed_order_buy: string | null;
  custodian__comp_id: string | null;
}

export interface OverridenPosition extends CommonModel {
  account_id: number;
  asset_id: number;
  quantity: string | null;
}

export interface Client {
  id: number;
  name: string;
  orion_client_id: number;
  representative: number;
  representative__name: string;
  as_of_date: string;
}

export interface Custodian extends CommonModel {
  name: string;
  comp_id: string | null;
  omnibus_account: string | null;
  as_of_date: string;
}

export interface SettlementDate {
  id: number;
  date: string;
}

export interface Position {
  id: number;
  asset__symbol: string;
  asset__name: string;
  asset__asset_category__name: string;
  asset__asset_class__name: string;
  asset__product_type__name: string;
  asset__product_sub_type__name: string;
  created_on: string;
  created_by: string;
  updated_on: string;
  updated_by: string;
  is_active: boolean;
  as_of_date: string;
  quantity: number;
  available_quantity: number | null;
  price: number;
  market_value: string;
  weight: number;
  unrealized_gain_loss: string;
  purchase_date: string;
  cost_basis: number;
  included_in_billing: boolean;
  outside_id: number;
  account: number;
  asset: number;
  mgmt_model: number;
  mgmt_style: number;
  representative: number;
}

export interface PortfolioStats {
  as_of_date: string;
  total_market_value: number;
  total_unrealized_gain_loss: number;
}

export interface RegType extends CommonModel {
  name: string;
  as_of_date: string;
}

export interface Representative extends CommonModel {
  as_of_date: string;
  rep_id: number | null;
  name: string;
  firm: number | null;
}

export interface Strategy {
  id: number;
  name: string;
  strategy_group: number;
  asset: number;
  current_call: number | null;
  current_call__symbol: string | null;
  current_call__name: string | null;
  hedge_ratio: string;
  target_delta: string;
  cost_basis?: string | null;
  current_call_price: string | null;
  total_stocks_qty?: number | null;
  orion_stock_last_price?: string | null;
  aum?: number | null;
  optionSymbolParams: OptionSymbolParams | null;
  option_qty?: string | null;
  executed_sell_qty?: string | null;
  executed_buy_qty?: string | null;
  last_call_close?: string | null;
  ex_date_last?: string | null;
  div_amt_last?: string | null;
  ex_date_next?: string | null;
  div_amt_next?: string | null;
  next_earnings_date?: string | null;
}

export interface StrategyGroup {
  id: number;
  name: string;
}

export interface Order {
  id?: number;
  account: number;
  asset: number;
  quantity: number;
  type: string;
  side: string;
  status?: string;
}

export interface NewCall {
  as_of_date: string;
  name: string;
  symbol: string;
}

export interface NotIncludedAccount extends Account {
  stock_qty: string;
}

export interface FactSetOption {
  REQ_SYM: string;
  OPT_CALL_OR_PUT: string;
  EXPIRATION_DATE: number;
  STRIKE_PRICE: number;
  LAST_PRICE: number;
  ASK: number;
  BID: number;
  DAYS_TO_EXPIRATION: number;
}

export interface NonOpticPosition extends CommonModel {
  name: string;
  symbol: string;
  client_name: string;
  account_number: string;
  custodian_name: string;
  option_qty: number;
}

export interface OptionChainParams {
  stock_symbol: String;
  strike_range: Number;
  exp_start: Number;
  exp_end: Number;
}

export interface RemoveAccountFromStrategyParams {
  id: number;
  strategy_id?: number;
  group_id?: number;
}

export interface UnseenAlertCountParams {
  user_id: number;
  alert_type?: string;
}

export interface StockHVParams {
  symbol: string;
  vol_days: Number;
}

export interface StockHV {
  symbol: string;
  vol_days: string;
  historical_volatility: string | null;
}

export interface CompositeReportParams {
  composite_id: number;
  start_date: string;
  end_date: string;
}

export interface CompositeReportPreviewParams extends CompositeReportParams{
  account_id: number;
}

export interface ReportOpenPosition {
  id: number;
  symbol: string;
  qty: number;
  price: number;
  created_on_date: number;
  close_price: number;
}

export interface CompositeReportItem {
  account_number: string;
  start_date: string;
  end_date: string;
  start_value: number;
  end_value: number;
  perfomance: number;
  portfolio_percent?: number;
  weighted_average?: number;
  dividend_income: number;
}

export interface OrionDividendTransaction {
  ticker: string;
  transDate: string;
  type: string;
  unitBalance: number; // shares qty
  transAmount: number; // income from transaction
}

export interface CompositeReportPreview extends CompositeReportItem {
  shares_data: {
    symbol: string;
    data: {
      start: {
        symbol: string;
        price: number;
        date: string;
        amount: number;
        overriden: boolean;
      };
      end: {
        symbol: string;
        price: number;
        date: string;
        amount: number;
        overriden: boolean;
      };
    };
  }[];
  shares_start_value: number;
  start_adjustment: number;
  shares_end_value: number;
  end_adjustment: number;
  cash_flow: number;
  orders_info: {
    id: number;
    created_on_date: string;
    qty: number;
    price: number;
    side: string;
    change: number;
    symbol: string;
  }[];
  start_open_positions: ReportOpenPosition[];
  end_open_positions: ReportOpenPosition[];
  dividend_transactions: OrionDividendTransaction[];
}

export interface CompositeReportJob {
  key: string;
  status: CompositeReportJobStatus;
  result: CompositeReportItem[] | null;
  status_message: string | null;
}

export const apiWithTrading = api
  .enhanceEndpoints({ addTagTypes: [
    "Acccounts",
    "AccountStrategies",
    "Strategies",
    "StrategyPositions",
    "Alerts",
    "Clients",
    "NotIncludedAccounts",
    "UserAlert",
    "CompositeReport",
  ]})
  .injectEndpoints({
    endpoints: (builder) => ({
      addAccountStrategy: builder.mutation<AccountStrategy, Partial<AccountStrategy>>({
        query: ({ ...post }) => ({
          url: `/trading/account_strategies/`,
          method: "POST",
          body: post
        }),
        invalidatesTags: ["AccountStrategies", "StrategyPositions", "NotIncludedAccounts"],
      }),
      removeAccountStrategy: builder.mutation({
        query: (id) => ({
          url: `/trading/account_strategies/${id}/`,
          method: 'DELETE'
        }),
        invalidatesTags: ["AccountStrategies"]
      }),
      listAlerts: builder.query<ListResponse<Alert>, GridModel>({
        query: (grid) => ({
          url: `/trading/alerts/`,
          params: gridModelsToQuery(grid),
          method: "GET"
        }),
        providesTags: ["Alerts"]
      }),
      listUserAlerts: builder.query<ListResponse<UserAlert>, GridModel>({
        query: (grid) => ({
          url: `/trading/user_alerts/`,
          params: gridModelsToQuery(grid),
          method: "GET",
        }),
        providesTags: ["UserAlert"],
      }),
      updateUserAlert: builder.mutation<UserAlert, Partial<UserAlert> & Pick<UserAlert, 'id'>>({
        query: ({ id, ...patch }) => ({
          url: `/trading/user_alerts/${id}/`,
          body: patch,
          method: "PATCH",
        }),
        invalidatesTags: ["UserAlert"],
      }),
      getUnseenAlertCount: builder.query<{ count: number}, UnseenAlertCountParams>({
        query: (params) => ({
          url: `/trading/user_alerts/unseen_count/`,
          params,
          method: "GET"
        }),
        providesTags: ["UserAlert"],
      }),
      listAccountStrategies: builder.query<ListResponse<AccountStrategy>, GridModel>({
        query: (grid) => ({
          url: `/trading/account_strategies/`,
          params: gridModelsToQuery(grid),
          method: "GET"
        }),
        providesTags: ["AccountStrategies"]
      }),
      listClients: builder.query<ListResponse<Client>, GridModel>({
        query: (grid) => ({
          url: `/trading/clients/`,
          params: gridModelsToQuery(grid),
          method: "GET",
        }),
        providesTags: ["Clients"]
      }),
      getClient: builder.query<Client, number | undefined>({
        query: (id) => ({
          url: `/trading/clients/${id}/`,
          method: "GET",
        }),
      }),
      listAccounts: builder.query<ListResponse<Account>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/accounts/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
        providesTags: ["Acccounts"],
      }),
      listSettlementDates: builder.query<ListResponse<SettlementDate>, GridModel>(
        {
          query: (gridModel) => ({
            url: `/trading/settlement_dates/`,
            params: gridModelsToQuery(gridModel),
            method: "GET",
          }),
        },
      ),
      listPositions: builder.query<ListResponse<Position>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/positions/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
      }),
      listStrategyPositions: builder.query<ListResponse<StrategyPosition>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/strategy_positions/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
        providesTags: ["StrategyPositions"]
      }),
      listStrategies: builder.query<ListResponse<Strategy>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/strategies/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
        providesTags: ["Strategies"],
      }),
      updateStrategy: builder.mutation<Strategy, Partial<Strategy> & Pick<Strategy, 'id'>>({
        query: ({ id, ...patch }) => ({
          url: `/trading/strategies/${id}/`,
          body: patch,
          method: "PATCH",
        }),
        invalidatesTags: ["Strategies"],
      }),
      listStrategyGroups: builder.query<ListResponse<StrategyGroup>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/strategy_groups/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
      }),
      getStats: builder.query<ListResponse<PortfolioStats>, void>({
        query: () => ({
          url: `/trading/stats/`,
          method: "GET",
        }),
      }),
      updateAccountStrategy: builder.mutation<AccountStrategy, Partial<AccountStrategy> & Pick<AccountStrategy, 'id'>>({
        query: ({ id, ...patch }) => ({
          url: `/trading/account_strategies/${id}/`,
          method: "PATCH",
          body: patch,
        }),
        invalidatesTags: ["StrategyPositions", "AccountStrategies"]
      }),
      updateAlert: builder.mutation<Alert, Partial<Alert> & Pick<Alert, 'id'>>({
        query: ({ id, ...patch }) => ({
          url: `/trading/alerts/${id}/`,
          method: "PATCH",
          body: patch,
        }),
        invalidatesTags: ["Alerts"]
      }),
      newOrder: builder.mutation<Order, Order> ({
        query: (order) => ({
          url: `/trading/orders/`,
          method: "POST",
          body: order,
        }),
        invalidatesTags: ["StrategyPositions"] 
      }),
      newOrdersBulk: builder.mutation<ListResponse<Order>, Order[]>({
        query: (orders) => ({
          url: `/trading/orders/bulk/`,
          method: "POST",
          body: orders
        }),
        invalidatesTags: ["StrategyPositions"],
      }),
      processOrders: builder.mutation<void, { asset_id: number }> ({
        query: (params) => ({
          url: `/trading/orders/process/`,
          method: "POST",
          body: params,
        }),
        invalidatesTags: ["StrategyPositions"],
      }),
      sendStagedOrders: builder.mutation<void, { asset_id: number }> ({
        query: (params) => ({
          url: `/trading/orders/send_staged/`,
          method: "POST",
          body: params,
        }),
      }),
      deleteStagedOrders: builder.mutation<void, { asset_id: number }> ({
        query: (params) => ({
          url: `/trading/orders/delete_staged/`,
          method: "POST",
          body: params,
        }),
        invalidatesTags: ["StrategyPositions"],
      }),
      listOrders: builder.query<ListResponse<Order>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/orders/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
      }),
      listRepresentatives: builder.query<ListResponse<Representative>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/representatives/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
      }),
      addNewClient: builder.mutation<Client, Partial<Client>>({
        query: ({ ...post }) => ({
          url: `/trading/clients/`,
          method: "POST",
          body: post,
        }),
        invalidatesTags: ["Clients"]
      }),
      removeClient: builder.mutation({
        query: (id) => ({
          url: `/trading/clients/${id}/`,
          method: "DELETE"
        }),
        invalidatesTags: ["Clients"]
      }),
      updateClient: builder.mutation<Client, Partial<Client> & Pick<Client, 'id'>>({
        query: ({ id, ...patch }) => ({
          url: `/trading/clients/${id}/`,
          method: "PATCH",
          body: patch,
        }),
        invalidatesTags: ["Clients"],
      }),
      listCustodians: builder.query<ListResponse<Custodian>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/custodians/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
      }),
      listRegTypes: builder.query<ListResponse<RegType>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/reg_types/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
      }),
      newAccount: builder.mutation<Account, Partial<Account>>({
        query: ({ ...post }) => ({
          url: `/trading/accounts/`,
          method: "POST",
          body: post,
        }),
        invalidatesTags: ["Acccounts"],
      }),
      removeAccount: builder.mutation({
        query: (id) => ({
          url: `/trading/accounts/${id}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["Acccounts"],
      }),
      updateAccount: builder.mutation<Account, Partial<Account> & Pick<Account, 'id'>>({
        query: ({ id, ...patch }) => ({
          url: `/trading/accounts/${id}/`,
          method: "PATCH",
          body: patch,
        }),
        invalidatesTags: ["Acccounts"],
      }),
      listAdvisors: builder.query<ListResponse<Advisor>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/advisors/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
      }),
      getAssetById: builder.query<Asset, Pick<Asset, 'id'>>({
        query: ({ id }) => ({
          url: `/trading/assets/${id}/`,
          method: "GET",
        }),
      }),
      newCall: builder.mutation<Strategy, Partial<NewCall & Pick<Strategy, 'id'>>>({
        query: ({ id, ...post }) => ({
          url: `/trading/strategies/${id}/new_call/`,
          method: "POST",
          body: post,
        }),
        invalidatesTags: ["Strategies", "StrategyPositions"],
      }),
      closeCall: builder.mutation<Strategy, Pick<Strategy, 'id'>>({
        query: ({ id }) => ({
          url: `/trading/strategies/${id}/close_call/`,
          method: "POST",
        }),
        invalidatesTags: ["Strategies", "StrategyPositions"],
      }),
      listNotIncludedAccounts: builder.query<ListResponse<NotIncludedAccount>, GridModel & Pick<Strategy, "id">>({
        query: ({ id, ...gridModel }) => ({
          url: `/trading/strategies/${id}/not_included_accounts/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
        providesTags: ["NotIncludedAccounts"],
      }),
      listOptionChain: builder.query<FactSetOption[], OptionChainParams>({
        query: (params) => ({
          url: `/trading/option_chain/`,
          body: params,
          method: "POST",
        }),
      }),
      fillStrategyStockWithAccounts: builder.mutation<AccountStrategy[], Pick<Strategy, "id">>({
        query: ({ id }) => ({
          url: `/trading/strategies/${id}/fill_with_accounts/`,
          method: "POST",
        }),
        invalidatesTags: ["StrategyPositions", "NotIncludedAccounts"],
      }),
      removeAccountFromStrategy: builder.mutation<void, RemoveAccountFromStrategyParams>({
        query: ({ id, ...post }) => ({
          url: `/trading/accounts/${id}/remove_from_strategy/`,
          method: "POST",
          body: post,
        }),
        invalidatesTags: ["StrategyPositions", "NotIncludedAccounts"],
      }),
      listNonOpticPositions: builder.query<ListResponse<NonOpticPosition>, GridModel>({
        query: (gridModel) => ({
          url: `/trading/non_optic_positions/`,
          params: gridModelsToQuery(gridModel),
          method: "GET",
        }),
      }),
      overridePositionQuery: builder.mutation<OverridenPosition | {}, Partial<OverridenPosition>>({
        query: ({ ...post }) => ({
          url: `/trading/positions/override/`,
          method: "POST",
          body: post,
        }),
        invalidatesTags: ["StrategyPositions"],
      }),
      getStockHistoricalVolatility: builder.query<StockHV, StockHVParams>({
        query: ({ symbol, vol_days }) => ({
          url: `/trading/historical_volatility/?symbol=${symbol}&vol_days=${vol_days}`,
          method: "GET",
        }),
      }),
      getCompositeReportPreview: builder.query<CompositeReportPreview, CompositeReportPreviewParams>({
        query: (params) => ({
          url: `/trading/composite_report_preview/?${new URLSearchParams(params as any).toString()}`,
          method: "GET",
        }),
      }),
      startCompositeReportJob: builder.mutation<CompositeReportJob, CompositeReportParams>({
        query: ({ ...post }) => ({
          url: `/trading/composite_report/start_job/`,
          method: "POST",
          body: post,
        }),
        invalidatesTags: ["CompositeReport"],
      }),
      getCompositeReport: builder.query<CompositeReportJob, { key: string }>({
        query: ({ key }) => ({
          url: `/trading/composite_report/?key=${key}`,
          method: "GET",
        }),
        providesTags: ["CompositeReport"],
      }),
    }),
});

export const {
  useAddAccountStrategyMutation,
  useRemoveAccountStrategyMutation,
  useListClientsQuery,
  useListAccountsQuery,
  useListAccountStrategiesQuery,
  useListAlertsQuery,
  useGetClientQuery,
  useListSettlementDatesQuery,
  useListPositionsQuery,
  useListStrategyPositionsQuery,
  useListStrategiesQuery,
  useListStrategyGroupsQuery,
  useGetStatsQuery,
  useUpdateAccountStrategyMutation,
  useUpdateAlertMutation,
  useNewOrderMutation,
  useNewOrdersBulkMutation,
  useListOrdersQuery,
  useListRepresentativesQuery,
  useAddNewClientMutation,
  useRemoveClientMutation,
  useUpdateClientMutation,
  useProcessOrdersMutation,
  useListCustodiansQuery,
  useListRegTypesQuery,
  useNewAccountMutation,
  useRemoveAccountMutation,
  useUpdateAccountMutation,
  useListAdvisorsQuery,
  useSendStagedOrdersMutation,
  useNewCallMutation,
  useCloseCallMutation,
  useListNotIncludedAccountsQuery,
  useDeleteStagedOrdersMutation,
  useListOptionChainQuery,
  useGetAssetByIdQuery,
  useFillStrategyStockWithAccountsMutation,
  useRemoveAccountFromStrategyMutation,
  useUpdateStrategyMutation,
  useListNonOpticPositionsQuery,
  useOverridePositionQueryMutation,
  useListUserAlertsQuery,
  useGetUnseenAlertCountQuery,
  useUpdateUserAlertMutation,
  useGetStockHistoricalVolatilityQuery,
  useGetCompositeReportPreviewQuery,
  useStartCompositeReportJobMutation,
  useGetCompositeReportQuery,
} = apiWithTrading;
