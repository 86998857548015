import { CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Slider, Stack, Switch, Typography } from "@mui/material";
import { FDS_ActionTypes, FDS_SubscriptionTypes, VolDaysOptions } from "constants/index";
import { addSubscription, cacheMessage, clearCache, removeSubscription } from "features/factset-streaming/factsetStreamingSlice";
import { useFactsetStreaming } from "features/store";
import { generateStrikeIntervals } from "helpers";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import WebSocketWrapper from "ws/WebSocketWrapper";
import { OptionGroup } from "./OptionGroup";
import { useGetStockHistoricalVolatilityQuery } from "features/trading/accounts";

interface Props {
  stockSymbol?: string;
  onClose?: Function;
  inModal?: boolean;
}

export const OptionChain = ({ stockSymbol, onClose, inModal }: Props) => {
  const dispatch = useDispatch();
  const {
    subscriptions,
    dataCache: {
      [FDS_SubscriptionTypes.STOCK_PRICE]: stockPrices,
      [FDS_SubscriptionTypes.OPTION_CHAIN]: optionChain,
      [FDS_SubscriptionTypes.OPTION_CONTRACT]: optionContracts,
    }
  } = useFactsetStreaming();

  const [isOcLoading, setOcIsLoading] = useState<boolean>(false);
  const strikeRangeIntervals = generateStrikeIntervals(50);
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false);

  const [strikeRange, setStrikeRange] = useState<number>(10);
  const [sliderRangeView, setSliderRangeView] = useState<number[]>([0, 0]);
  const [sliderRange, setSliderRange] = useState<number[]>([0, 0]);
  const [openedOptionGroup, setOpenedOptionGroup] = useState<string | undefined>();

  const [selectedOption, setSelectedOption] = useState<
    any | undefined
  >();

  const [volDays, setVolDays] = useState<Number>(VolDaysOptions[0].value);
  const { data: hvData } = useGetStockHistoricalVolatilityQuery(
    {
      symbol: stockSymbol!,
      vol_days: volDays,
    },
    { skip: !stockSymbol }
  );

  useEffect(() => () => { dispatch(clearCache(null)) }, [])

  const stockPriceKey = useMemo(() => {
    if (!stockSymbol) {
      return null;
    }

    return FDS_SubscriptionTypes.STOCK_PRICE + ":" + stockSymbol;
  }, [stockSymbol]);

  useEffect(() => {
    if (!stockPriceKey) {
      return;
    }

    const ws = new WebSocketWrapper();
    if (!(stockPriceKey in subscriptions)) {
      ws.connect(process.env.REACT_APP_FDS_URL! + "/fds");

      ws.on("open", (ev: any) => {
        if (stockPriceKey && !(stockPriceKey in subscriptions)) {
          const [_, symbol] = stockPriceKey.split(":");
          dispatch(addSubscription(stockPriceKey));
          ws.send(JSON.stringify({
            action: FDS_ActionTypes.SUBSCRIBE,
            type: FDS_SubscriptionTypes.STOCK_PRICE,
            symbol,
          }));
        }
      });

      ws.on("message", (ev: any) => {
        if (ev.data !== "ok") {
          dispatch(cacheMessage(JSON.parse(ev.data)));
        }
      });

      ws.on("close", (ev: any) => {
        if (stockPriceKey) {
          dispatch(removeSubscription(stockPriceKey));
        }
      });
    }

    return () => {
      if (stockPriceKey) {
        const [_, symbol] = stockPriceKey.split(":");
        dispatch(removeSubscription(stockPriceKey));
        ws.send(JSON.stringify({
          action: FDS_ActionTypes.UNSUBSCRIBE,
          type: FDS_SubscriptionTypes.STOCK_PRICE,
          symbol,
        }));
      }
    };
  }, [stockPriceKey]);

  const stockPriceSetRef = useRef<any>(false);

  const lastStockPrice = useMemo(() => {
    if (!stockSymbol) {
      return "N/A";
    }

    if (!(stockSymbol in stockPrices)) {
      return "N/A";
    }

    return stockPrices[stockSymbol]?.last_price || "N/A";
  }, [stockSymbol, stockPrices]);

  const [integerLastStockPrice, setIntegerLastStockPrice] = useState<number | null>(null);

  useEffect(() => {
    if (!integerLastStockPrice) {
      return;
    }

    const hundredsCount = integerLastStockPrice / 100 | 0;
    const referencePrice = hundredsCount >= 1 ? hundredsCount * 100 : 100;
    setSliderRangeView([referencePrice - 50, referencePrice + 50]);
    setSliderRange([referencePrice - 50, referencePrice + 50]);
  }, [integerLastStockPrice]);

  useEffect(() => {
    if (!lastStockPrice || lastStockPrice === "N/A") {
      return;
    }

    if (!stockPriceSetRef.current) {
      stockPriceSetRef.current = true;
      setIntegerLastStockPrice(parseInt(lastStockPrice));
    }
  }, [lastStockPrice]);

  const ocRequestParams = useMemo(() => {
    if (!integerLastStockPrice) {
      return null;
    }

    if (isSwitchChecked) {
      const [sMin, sMax] = sliderRange;
      return `call&nearThreeMonths&strikes=${sMin}-${sMax}`;
    }

    let min = integerLastStockPrice - strikeRange;
    min = min < 0 ? 0 : min;
    const max = integerLastStockPrice + strikeRange;

    return `call&nearThreeMonths&strikes=${min}-${max}`;
  }, [integerLastStockPrice, strikeRange, isSwitchChecked, sliderRange]);

  const ocKey = useMemo(() => {
    if (!stockSymbol || !ocRequestParams) {
      return null;
    }

    return FDS_SubscriptionTypes.OPTION_CHAIN + ":" + stockSymbol + ocRequestParams;
  }, [stockSymbol, ocRequestParams]);

  useEffect(() => {
    if (!ocKey) {
      return;
    }

    setOcIsLoading(true);

    const ws = new WebSocketWrapper();

    ws.connect(process.env.REACT_APP_FDS_URL! + "/fds");

    ws.on("open", (ev: any) => {
      ws.send(JSON.stringify({
        action: FDS_ActionTypes.GET,
        type: FDS_SubscriptionTypes.OPTION_CHAIN,
        symbol: stockSymbol,
        requestParams: ocRequestParams,
      }));
    });

    ws.on("message", (ev: any) => {
      if (ev.data !== "ok")  {
        dispatch(cacheMessage(JSON.parse(ev.data)));
        ws.disconect();
        setOcIsLoading(false);
      }
    });
  }, [stockSymbol, ocKey]);

  const optionGroups = useMemo(() => {
    const keys = Object.keys(optionChain).filter((v) => v !== "streamer_key" && v !== "subscription_type");
    if (keys.length === 0) {
      return null;
    }

    const options = keys.map((key) => {
      const ocItem = optionChain[key];
      const contractItem = optionContracts[key];
      if (!contractItem) {
        return { ...ocItem };
      }
      return { ...ocItem, ...contractItem };
    });

    const groupedByExpDate = options.reduce((acc, option) => {
      const { expiration_date } = option;
      if (acc[expiration_date]) {
        acc[expiration_date].push(option);
      } else {
        acc[expiration_date] = [option];
      }

      return acc;
    }, {});

    const result = Object.keys(groupedByExpDate)
      .sort()
      .map((expDate: string) => {
        return {
          expirationDate: expDate,
          options: groupedByExpDate[expDate].sort((a: any, b: any) => {
            return parseFloat(a.strike_price) - parseFloat(b.strike_price);
          }),
        };
      });
    return result;
  }, [optionChain, optionContracts]);

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          {stockSymbol && (
            <Typography sx={{ padding: 1 }}>
              Stock Price: {
                lastStockPrice !== "N/A"
                ? Number(lastStockPrice).toFixed(2) + "$"
                : lastStockPrice
              }
            </Typography>
          )}
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ marginX: 1, height: "72px" }} />
        <Grid>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography>Select</Typography>
            <Switch
              disabled={!integerLastStockPrice}
              value={isSwitchChecked}
              onChange={(e) => setIsSwitchChecked(e.target.checked)}
            />
            <Typography>Slider</Typography>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ marginX: 1, height: "72px" }} />
        <Grid>
          <Grid container sx={{ padding: 1 }} alignItems="center">
            {isSwitchChecked
              ? (
                <Grid container alignItems="center">
                  <Typography>Strike Range</Typography>
                  <Slider
                    min={0}
                    max={1500}
                    sx={{ width: "600px", marginLeft: 2 }}
                    step={50}
                    marks
                    valueLabelDisplay="on"
                    value={sliderRangeView}
                    onChange={(_, value) => {
                      setSliderRangeView(value as number[]);
                    }}
                    onChangeCommitted={(_, value) => {
                      setSliderRangeView(value as number[]);
                      setSliderRange(value as number[]);
                    }}/>
                </Grid>
              )
              : (
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography>Strike Range +/-</Typography>
                  </Grid>
                  <Grid item sx={{ paddingLeft: 1 }}>
                    <Select
                      value={strikeRange}
                      onChange={(e) => setStrikeRange(Number(e.target.value))}
                    >
                      {strikeRangeIntervals.map((interval: number) => (
                        <MenuItem key={interval} value={interval}>
                          {interval}$
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        </Grid>
        <Grid xs={12} container alignItems="center" marginTop={1}>
          <Grid item>
            <FormControl sx={{ padding: 1 }}>
              <InputLabel>Vol Days</InputLabel>
              <Select
                label="Vol Days"
                value={volDays}
                onChange={(e) => setVolDays(e.target.value as Number)}
                sx={{ width: "120px" }}
              >
                {VolDaysOptions.map((opt: any) => (
                  <MenuItem
                    key={opt.value}
                    value={opt.value}
                  >
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography width="120px" sx={{ margin: 1 }}>
              HV: {hvData?.historical_volatility || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{ height: "800px", overflowY: "auto" }}>
        {isOcLoading && <CircularProgress />}
        {!isOcLoading && optionGroups &&
          optionGroups.map((optGroup: any) => (
            <OptionGroup
              expirationDate={optGroup.expirationDate}
              options={optGroup.options}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              opened={optGroup.expirationDate === openedOptionGroup}
              onOpen={() => setOpenedOptionGroup(optGroup.expirationDate)}
              onClose={() => setOpenedOptionGroup(undefined)}
              closeModal={onClose}
              inModal={inModal}
            />
          ))}
      </Grid>
    </>
  );
};

export default OptionChain;
