import { RootState } from "features/store";
import api from "../api";
import { QueryReturnValue } from "@reduxjs/toolkit/query";
import {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  access: string;
  refresh: string;
}

export interface User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  last_login: string;
}

export interface CheckAuthResponse {}

export const apiWithAuth = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      invalidatesTags: ["Auth"],
      query: (credentials) => ({
        url: `/auth/token/`,
        method: "POST",
        body: credentials,
      }),
    }),

    checkAuth: builder.mutation<CheckAuthResponse, void>({
      invalidatesTags: ["Auth"],
      queryFn: async (_arg, api, _extraOptions, baseQuery) => {
        if ((api.getState() as RootState).auth.accessToken) {
          return baseQuery({
            url: `/auth/token/verify/`,
            method: "POST",
            body: { token: localStorage.getItem("accessToken") },
          }) as QueryReturnValue<
              CheckAuthResponse,
              FetchBaseQueryError,
              FetchBaseQueryMeta
            >;
        } else {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: "Access token not found",
            },
          };
        }
      },
    }),

    logout: builder.mutation<void, void>({
      queryFn: () => ({ data: undefined }),
    }),

    getUser: builder.query<any, void>({
      providesTags: ["Auth"],
      queryFn: (_arg, api, _extraOptions, baseQuery) => {
        if ((api.getState() as RootState).auth.isAuthenticated) {
          return baseQuery(`/users/me/`);
        } else {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error: "Not authenticated",
            },
          };
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useCheckAuthMutation,
  useLogoutMutation,
  useGetUserQuery,
} = apiWithAuth;
